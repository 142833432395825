
import Vue from 'vue';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import workplaceExtensionApi from 'src/apis/workplace_masters/workplace_extension';
import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';
import staffApi from 'src/apis/workplace_masters/staff';
import staffAgencyApi from 'src/apis/workplace_masters/staff_agency';
import staffLabelApi from 'src/apis/workplace_masters/staff_label';
import budgetGroupApi from 'src/apis/workplace_masters/budget_group';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import ListSwitchPanel from 'src/views/Dashboard/Workplace/Settings/ListSwitchPanel.vue';
import keyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import forkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import customFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import customFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import customFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import keyPlayerGrayIcon from 'src/components/Staffs/keyPlayerGrayIcon.vue';
import forkliftGrayIcon from 'src/components/Staffs/forkliftGrayIcon.vue';
import customFlagGrayIcon from 'src/components/Staffs/customFlagGrayIcon.vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import {
  defineComponent,
  SetupContext,
  computed,
  reactive,
  onMounted,
  onUnmounted,
  ref,
  getCurrentInstance,
  ComputedRef,
  watch,
} from '@vue/composition-api';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { BudgetGroup } from 'src/models/budgetGroup';
import { StaffLabel } from 'src/models/staffLabel';
import { StaffWorkSchedule } from 'src/models/staffWorkSchedule';
import { StaffAgency } from 'src/models/staffAgency';
import { Staff } from 'src/models/staff';
import { vvHasError, vvGetError, vvReset, vvValidate } from 'src/util/vee_validate';
import { isExist } from 'src/util/isExist';
import { Tabs, TabPane, Card } from 'src/components/UIComponents';
import Sorter2 from 'src/components/Workplace/Sorter2.vue';
import { SortSpec, useSorter2Container } from 'src/components/Workplace/Sorter2Container.vue';
import PaginationContainer from 'src/components/UIComponents/PaginationContainer.vue';
import { StaffSkill } from 'src/models/staffSkill';
import {
  STAFF_GENDER,
  STAFF_TYPE,
  StaffGender,
  StaffGenderSelectOptions,
  StaffType,
  StaffTypeSelectOptions,
} from 'src/consts';
import { TimetableMaster } from 'src/models/timetableMaster';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { WorkplaceExtension } from 'src/models/workplaceExtension';
import {
  convertToStaffWorkScheduleFormValues,
  findDayOfWeekSchedule,
  formatDate,
  updateCandidateByShiftPattern,
  type Operation,
  type WeekKey,
  type SaveCandidate,
  type LabelName,
  type WeekName,
  MONDAY,
  THURSDAY,
  WEDNESDAY,
  TUESDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
  getDayValue,
  StaffWorkScheduleFormValues,
} from 'src/views/Dashboard/Workplace/Settings/StaffExtension/setting';
import { MacroOperationMaster } from 'src/models/macroOperationMaster';
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern';
import { ShiftPatternSelectModal } from './components/ShiftPatternSelectModal';
import { useShiftPatternSelectModal } from './composables/useShiftPatternSelectModal';
import { formatTimeInteger, packToTimeIntegerWithGuard } from 'src/util/datetime';
import { StaffWorkScheduleBulkUpdateParams } from 'src/models/api/staffWorkScheduleRequest';
import CsvUploadButton from 'src/components/UIComponents/Buttons/CsvUploadButton/CsvUploadButton.vue';
import { useCsvUploadResultModalProvider } from './composables/useCsvUploadResultModal';
import { useUploadStaffCsv } from './composables/useUploadStaffCsv';
import { CsvUploadResultModal } from './components/CsvUploadResultModal';
import { useDutyTimeValidation } from './composables/useDutyTimeValidation';

const msgVars = { update: '編集', bulkUpdate: '一括編集' };
const errIds = { update: 'ERR00002', bulkUpdate: 'ERR00003' };
const displayPageName = 'スタッフ ロジボード向け';
const MACRO_OPERATION_MASTER_ID_NO_CHANGE = 0;
const MEMO_MAX_LENGTH = 255;
const week: { key: WeekKey; name: WeekName; value: number }[] = [
  { key: 'mon', name: '月', value: 1 },
  { key: 'tue', name: '火', value: 2 },
  { key: 'wed', name: '水', value: 3 },
  { key: 'thu', name: '木', value: 4 },
  { key: 'fri', name: '金', value: 5 },
  { key: 'sat', name: '土', value: 6 },
  { key: 'sun', name: '日', value: 0 },
];

interface StaffDisp extends Staff {
  fullName: string;
  fullNameKana: string;
  sortMonWorkTime: number;
  sortTueWorkTime: number;
  sortWedWorkTime: number;
  sortThuWorkTime: number;
  sortFriWorkTime: number;
  sortSatWorkTime: number;
  sortSunWorkTime: number;
  sortMacroOperationName: string;
  mon: StaffWorkSchedule | null;
  tue: StaffWorkSchedule | null;
  wed: StaffWorkSchedule | null;
  thu: StaffWorkSchedule | null;
  fri: StaffWorkSchedule | null;
  sat: StaffWorkSchedule | null;
  sun: StaffWorkSchedule | null;
  skill1: { name: string; color: string };
  skill2: { name: string; color: string };
  skill3: { name: string; color: string };
  skill4: { name: string; color: string };
  skill5: { name: string; color: string };
}

interface Pagination {
  perPage: number;
  currentPage: number;
  total: number;
}

interface SearchParams {
  staff: Staff | null;
  staffName: string | null;
  staffNumber: string | null;
  budgetGroup: BudgetGroup | null;
  staffAgency: StaffAgency | null;
  staffLabel: StaffLabel | null;
  staffType: StaffType | null;
  staffGender: StaffGender | null;
  timetableMasterId: number | null;
  isKeyPlayer: boolean;
  isForkman: boolean;
  has_custom_skill1: boolean;
  has_custom_skill2: boolean;
  has_custom_skill3: boolean;
  displayListDisabledItem: boolean;
  macroOperationMasterId: number | null;
}

interface State {
  workplaceExtension: WorkplaceExtension | null;
  staffs: StaffDisp[];
  staffMasters: Staff[];
  selectedStaffs: Staff[];
  staffAgencies: StaffAgency[];
  staffLabels: StaffLabel[];
  budgetGroups: BudgetGroup[];
  timetableMasters: TimetableMaster[];
  selectedTimetableMasters: TimetableMaster[];
  filteredTimetableMasters: TimetableMaster[];
  macroOperationMasters: MacroOperationMaster[];
  selectedMacroOperationMasters: MacroOperationMaster[];
  filteredMacroOperationMasters: MacroOperationMaster[];
  saveCandidate: SaveCandidate;
  staffWorkScheduleFormValues: StaffWorkScheduleFormValues[];
  selectedStaff: StaffDisp | null;
  isMultipleBudgetGroupsTargeted: boolean;
  allSelectedItems: boolean;
  showSaveModal: boolean;
  isFormEditable: boolean;
  customSkills: { key: string; name: string }[];
  isSelectedTimeTableMastersUnique: boolean;
  customSkillStyle: string;
  validations: Record<string, Record<string, unknown>>;
}

function initialSaveCandidateState(): SaveCandidate {
  return {
    saveType: 'update',
    staff_id: null,
    is_shift_management_target: null,
    is_appropriation_target: null,
    is_key_player: null,
    is_forkman: null,
    has_custom_skill1: null,
    has_custom_skill2: null,
    has_custom_skill3: null,
    gender: null,
    staff_type: null,
    staff_work_schedules: [],
    macro_operation_master_id: null,
    staff_skills: [
      { priority: 1, timetable_master_id: null },
      { priority: 2, timetable_master_id: null },
      { priority: 3, timetable_master_id: null },
      { priority: 4, timetable_master_id: null },
      { priority: 5, timetable_master_id: null },
    ],
    is_cleared: [],
    staff_ids: [],
    memo: null,
  };
}

function buildInitialStaffWorkScheduleFormValues(mode: Operation): StaffWorkScheduleFormValues[] {
  const macroOperationMasterId = mode === 'bulkUpdate' ? MACRO_OPERATION_MASTER_ID_NO_CHANGE : null;
  return week.map((day) => {
    return {
      day_of_week: day.value,
      work_start_time_hour: null,
      work_start_time_min: null,
      work_end_time_hour: null,
      work_end_time_min: null,
      break1_start_time_hour: null,
      break1_start_time_min: null,
      break1_end_time_hour: null,
      break1_end_time_min: null,
      break2_start_time_hour: null,
      break2_start_time_min: null,
      break2_end_time_hour: null,
      break2_end_time_min: null,
      duty1_start_time_hour: null,
      duty1_start_time_min: null,
      duty1_end_time_hour: null,
      duty1_end_time_min: null,
      duty2_start_time_hour: null,
      duty2_start_time_min: null,
      duty2_end_time_hour: null,
      duty2_end_time_min: null,
      duty1_macro_operation_master_id: macroOperationMasterId,
      duty2_macro_operation_master_id: macroOperationMasterId,
    };
  });
}

function setupState(): State {
  const state: State = reactive({
    workplaceExtension: null,
    staffs: [],
    validations: computed(() => getValidationMaps(state)),
    staffMasters: [],
    selectedStaffs: [],
    staffAgencies: [],
    staffLabels: [],
    budgetGroups: [],
    timetableMasters: [],
    selectedTimetableMasters: [],
    filteredTimetableMasters: [],
    macroOperationMasters: [],
    selectedMacroOperationMasters: [],
    filteredMacroOperationMasters: [],
    saveCandidate: initialSaveCandidateState(),
    staffWorkScheduleFormValues: buildInitialStaffWorkScheduleFormValues('update'),
    selectedStaff: null,
    isMultipleBudgetGroupsTargeted: false,
    allSelectedItems: false,
    showSaveModal: false,
    isFormEditable: computed(() => {
      if (state.saveCandidate.saveType !== 'bulkUpdate') {
        return true;
      }
      return state.saveCandidate.staff_ids.length <= 50;
    }),
    customSkills: [],
    isSelectedTimeTableMastersUnique: computed(() => {
      const timetableMasterIds = state.saveCandidate.staff_skills
        .map((staff_skill) => staff_skill.timetable_master_id)
        .filter((el) => el !== null && el !== 0);
      const uniqueTimetableMasterIdSet = new Set(timetableMasterIds);
      return timetableMasterIds.length === uniqueTimetableMasterIdSet.size;
    }),
    customSkillStyle: '',
  });
  return state;
}
function getValidationMaps(state: State): Record<string, Object> {
  const ruleHour = { numeric: true, max: 2, max_value: 47 };
  const ruleMin = { numeric: true, max: 2, regex: /^(00|15|30|45)$/ };
  const staticRules = {
    work_start_time_hour: ruleHour,
    work_start_time_min: ruleMin,
    work_end_time_hour: ruleHour,
    work_end_time_min: ruleMin,
    break1_start_time_hour: ruleHour,
    break1_start_time_min: ruleMin,
    break1_end_time_hour: ruleHour,
    break1_end_time_min: ruleMin,
    break2_start_time_hour: ruleHour,
    break2_start_time_min: ruleMin,
    break2_end_time_hour: ruleHour,
    break2_end_time_min: ruleMin,
  };

  const { rules: dynamicRules } = useDutyTimeValidation(week, state.staffWorkScheduleFormValues);
  return { ...staticRules, ...dynamicRules };
}

export default defineComponent({
  components: {
    ListSwitchPanel,
    TabPane,
    Tabs,
    Card,
    Sorter2,
    Sorter2Container: useSorter2Container<StaffDisp>(),
    PaginationContainer,
    ShiftPatternSelectModal,
    keyPlayerIcon,
    forkliftIcon,
    customFlag1Icon,
    customFlag2Icon,
    customFlag3Icon,
    keyPlayerGrayIcon,
    forkliftGrayIcon,
    customFlagGrayIcon,
    CsvUploadButton,
    CsvUploadResultModal,
  },
  name: 'staff-extension',
  setup(_props, _context: SetupContext) {
    const root = _context.root as Vue;
    const vueInstance = getCurrentInstance()?.proxy.$root!;
    const state = setupState();
    const searchParams: SearchParams = reactive({
      staff: null,
      staffName: null,
      staffNumber: null,
      budgetGroup: null,
      staffAgency: null,
      staffLabel: null,
      staffType: null,
      staffGender: null,
      timetableMasterId: null,
      isKeyPlayer: false,
      isForkman: false,
      has_custom_skill1: false,
      has_custom_skill2: false,
      has_custom_skill3: false,
      macroOperationMasterId: null,
      displayListDisabledItem: false,
    });

    const userId: ComputedRef<number> = wrappedMapGetters(vueInstance.$store, 'user', ['id']).id;
    const workplaceId = computed(() => {
      return Number(vueInstance.$route.params.workplaceId);
    });
    const hasError = computed(() => {
      return vvHasError(vueInstance) || isMemoInvalid(state.saveCandidate.memo);
    });

    const pagination = reactive<Pagination>({
      perPage: 50,
      currentPage: 1,
      total: 0,
    });

    const defaultSortSpec = ref<SortSpec[]>([]);
    function resetToDefaultSortOrder(): void {
      defaultSortSpec.value = [{ key: 'sortFullNameKana', asc: true }];
    }
    resetToDefaultSortOrder();

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }
    function getApiParams() {
      return {
        staff_name: searchParams.staffName,
        staff_number: searchParams.staffNumber,
        staff_id: searchParams.staff?.id || null,
        budget_group_id: searchParams.budgetGroup?.id || null,
        staff_agency_id: searchParams.staffAgency?.id || null,
        staff_label_id: searchParams.staffLabel?.id || null,
        staff_type: searchParams.staffType,
        staff_gender: searchParams.staffGender,
        timetable_master_id: searchParams.timetableMasterId,
        is_key_player: searchParams.isKeyPlayer || null,
        is_forkman: searchParams.isForkman || null,
        has_custom_skill1: searchParams.has_custom_skill1 || null,
        has_custom_skill2: searchParams.has_custom_skill2 || null,
        has_custom_skill3: searchParams.has_custom_skill3 || null,
        macro_operation_master_id: searchParams.macroOperationMasterId,
        is_enabled: searchParams.displayListDisabledItem ? null : true,
      };
    }

    function clearErrors() {
      vvReset(vueInstance);
    }

    async function getList() {
      state.workplaceExtension = await workplaceExtensionApi.show(workplaceId.value);
      const customSkillCount = [
        state.workplaceExtension.custom_skill_name1,
        state.workplaceExtension.custom_skill_name2,
        state.workplaceExtension.custom_skill_name3,
      ].filter((value) => value !== null && value !== '').length;
      state.customSkillStyle = `custom-skill-setting${customSkillCount}`;
      const { data: budgetGroups } = await budgetGroupApi.index({
        workplaceId: workplaceId.value,
        params: { is_enabled: true },
      });

      const { data: staffs } = await staffApi.index({
        workplaceId: workplaceId.value,
        params: { is_enabled: true },
      });
      state.staffMasters = staffs;
      selectStaffMasters();

      const { data: staffAgencies } = await staffAgencyApi.index({
        workplaceId: workplaceId.value,
        params: { is_enabled: true },
      });

      const { data: staffLabels } = await staffLabelApi.index({
        workplaceId: workplaceId.value,
        params: { is_enabled: true },
      });

      state.timetableMasters = await timetableMasterApi.index({
        workplaceId: workplaceId.value,
        params: {
          budget_group_id: searchParams.budgetGroup?.id || undefined,
          is_enabled: true,
          use_in_prep_phase: true,
        },
      });
      selectTimetableMasters();
      state.macroOperationMasters = await macroOperationMasterApi.getSubWorkplaceList({
        workplaceId: workplaceId.value,
        params: { is_enabled: true },
      });
      selectMacroOperationMasters();
      await loadStaffs();

      state.staffAgencies = staffAgencies;
      state.staffLabels = staffLabels;
      state.budgetGroups = budgetGroups;
      const customSkills = [];
      if (state.workplaceExtension?.custom_skill_name1) {
        customSkills.push({ key: 'has_custom_skill1', name: state.workplaceExtension?.custom_skill_name1 });
      }
      if (state.workplaceExtension?.custom_skill_name2) {
        customSkills.push({ key: 'has_custom_skill2', name: state.workplaceExtension?.custom_skill_name2 });
      }
      if (state.workplaceExtension?.custom_skill_name3) {
        customSkills.push({ key: 'has_custom_skill3', name: state.workplaceExtension?.custom_skill_name3 });
      }
      state.customSkills = customSkills;
    }

    async function initializeSearchStaffsParams() {
      searchParams.staff = null;
      searchParams.macroOperationMasterId = null;
      searchParams.timetableMasterId = null;

      await loadStaffs();
      selectTimetableMasters();
      selectMacroOperationMasters();
      selectStaffMasters();
    }

    async function onChangeDutyMacroOperationMaster(day: WeekKey, num: number) {
      if (num !== 1 && num !== 2) {
        return;
      }

      const dayValue = getDayValue(day);
      const formValue = state.staffWorkScheduleFormValues.find((formValue) => formValue.day_of_week === dayValue);
      if (formValue === undefined) {
        return;
      }

      const macro_operation_master_id_input = formValue[`duty${num}_macro_operation_master_id`];

      state.saveCandidate.staff_work_schedules = state.saveCandidate.staff_work_schedules.map((staff_work_schedule) => {
        if (staff_work_schedule.day_of_week === dayValue) {
          return {
            ...staff_work_schedule,
            [`duty${num}_macro_operation_master_id`]: macro_operation_master_id_input,
            [`duty${num}_start_time`]:
              macro_operation_master_id_input !== null && macro_operation_master_id_input !== 0
                ? staff_work_schedule[`duty${num}_start_time`]
                : null,
            [`duty${num}_end_time`]:
              macro_operation_master_id_input !== null && macro_operation_master_id_input !== 0
                ? staff_work_schedule[`duty${num}_end_time`]
                : null,
          };
        } else {
          return staff_work_schedule;
        }
      });

      if (macro_operation_master_id_input !== null && macro_operation_master_id_input !== 0) {
        vvValidate(vueInstance, `${day}Duty${num}StartTimeHour`);
        vvValidate(vueInstance, `${day}Duty${num}StartTimeMin`);
        vvValidate(vueInstance, `${day}Duty${num}EndTimeHour`);
        vvValidate(vueInstance, `${day}Duty${num}EndTimeMin`);
        vvValidate(vueInstance, `${day}Duty${num}Time`);
      } else {
        state.staffWorkScheduleFormValues.forEach((formValue) => {
          if (formValue.day_of_week === dayValue) {
            formValue[`duty${num}_start_time_hour`] = null;
            formValue[`duty${num}_start_time_min`] = null;
            formValue[`duty${num}_end_time_hour`] = null;
            formValue[`duty${num}_end_time_min`] = null;
          }
        });
        vvReset(vueInstance, `${day}Duty${num}StartTimeHour`);
        vvReset(vueInstance, `${day}Duty${num}StartTimeMin`);
        vvReset(vueInstance, `${day}Duty${num}EndTimeHour`);
        vvReset(vueInstance, `${day}Duty${num}EndTimeMin`);
        vvReset(vueInstance, `${day}Duty${num}Time`);
      }
    }

    async function loadStaffs({ resetsCurrentPage = true }: { resetsCurrentPage?: boolean } = {}) {
      const staffs = await staffExtensionApi.staffs({
        workplaceId: workplaceId.value,
        params: getApiParams(),
      });
      state.staffs = addExtraItems(staffs);
      pagination.total = state.staffs.length;
      if (resetsCurrentPage) {
        pagination.currentPage = 1;
      }
    }

    async function getStaffsWithUpdatedSearchParams(params: { displayDisabledModel: boolean }) {
      searchParams.displayListDisabledItem = params.displayDisabledModel;
      await loadStaffs();
    }
    function sumWorkTime(workStartTime: number | null, workEndTime: number | null) {
      return (workStartTime ?? -1) + (workEndTime ?? -1);
    }
    function addExtraItems(staffs: Staff[]): StaffDisp[] {
      return staffs.map((e: Staff) => {
        const staffWorkSchedules = e.staff_extension?.staff_work_schedules;
        // null/空文字の場合ソートが効かないため'-'で対応
        const macroOperationName = isExist(e.staff_extension?.macro_operation_master)
          ? e.staff_extension?.macro_operation_master.name
          : '-';
        const monWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, MONDAY);
        const tueWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, TUESDAY);
        const wedWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, WEDNESDAY);
        const thuWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, THURSDAY);
        const friWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, FRIDAY);
        const satWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, SATURDAY);
        const sunWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, SUNDAY);
        return {
          ...e,
          sortMacroOperationName: macroOperationName,
          sortMonWorkTime:
            monWorkSchedule !== null ? sumWorkTime(monWorkSchedule.work_start_time, monWorkSchedule.work_end_time) : 0,
          sortTueWorkTime:
            tueWorkSchedule !== null ? sumWorkTime(tueWorkSchedule.work_start_time, tueWorkSchedule.work_end_time) : 0,
          sortWedWorkTime:
            wedWorkSchedule !== null ? sumWorkTime(wedWorkSchedule.work_start_time, wedWorkSchedule.work_end_time) : 0,
          sortThuWorkTime:
            thuWorkSchedule !== null ? sumWorkTime(thuWorkSchedule.work_start_time, thuWorkSchedule.work_end_time) : 0,
          sortFriWorkTime:
            friWorkSchedule !== null ? sumWorkTime(friWorkSchedule.work_start_time, friWorkSchedule.work_end_time) : 0,
          sortSatWorkTime:
            satWorkSchedule !== null ? sumWorkTime(satWorkSchedule.work_start_time, satWorkSchedule.work_end_time) : 0,
          sortSunWorkTime:
            sunWorkSchedule !== null ? sumWorkTime(sunWorkSchedule.work_start_time, sunWorkSchedule.work_end_time) : 0,
          fullName: `${e.family_name} ${e.first_name}`,
          fullNameKana: `${e.family_name_kana || ''} ${e.first_name_kana || ''}`,
          // 半角スペースソートのため鸞を入れる cf). src/assets/src/hooks/staffSortHook.ts
          sortFullNameKana: `${e.family_name_kana || '鸞'} ${e.first_name_kana || '鸞'}`,
          mon: monWorkSchedule,
          tue: tueWorkSchedule,
          wed: wedWorkSchedule,
          thu: thuWorkSchedule,
          fri: friWorkSchedule,
          sat: satWorkSchedule,
          sun: sunWorkSchedule,
          skill1: convertSkill(findStaffSkill(e.staff_skills, 1)),
          skill2: convertSkill(findStaffSkill(e.staff_skills, 2)),
          skill3: convertSkill(findStaffSkill(e.staff_skills, 3)),
          skill4: convertSkill(findStaffSkill(e.staff_skills, 4)),
          skill5: convertSkill(findStaffSkill(e.staff_skills, 5)),
        };
      });
    }

    function convertSkill(skill: StaffSkill | undefined) {
      if (skill === undefined) {
        return { id: 0, name: '-', color: 'FFFFFF' };
      }
      const timetableMaster = findTimetableMaster(skill.timetable_master_id);
      return {
        name: timetableMaster?.name || '-',
        color: timetableMaster?.disp_color || 'FFFFFF',
      };
    }
    async function openUpdateModal(staff: StaffDisp) {
      state.macroOperationMasters = [];
      await prepareUpdate(staff);
      attachValidate();
      state.showSaveModal = true;
    }

    async function openBulkUpdateModal() {
      state.macroOperationMasters = [];
      await prepareBulkUpdate();
      attachValidate();
      state.showSaveModal = true;
    }

    async function prepareBulkUpdate() {
      state.saveCandidate.saveType = 'bulkUpdate';
      const budgetGroupIds = [
        ...new Set(
          state.staffs.reduce((ids: number[], staff) => {
            if (state.saveCandidate.staff_ids.includes(staff.id)) {
              ids.push(staff.budget_group_id);
            }
            return ids;
          }, []),
        ),
      ];

      state.isMultipleBudgetGroupsTargeted = budgetGroupIds.length > 1;
      state.staffWorkScheduleFormValues = buildInitialStaffWorkScheduleFormValues('bulkUpdate');
      const staffWorkSchedules = week.map((dayOfWeek) => {
        return {
          day_of_week: dayOfWeek.value,
          work_start_time: null,
          work_end_time: null,
          break1_start_time: null,
          break1_end_time: null,
          break2_start_time: null,
          break2_end_time: null,
          duty1_macro_operation_master_id: 0,
          duty1_start_time: null,
          duty1_end_time: null,
          duty2_macro_operation_master_id: 0,
          duty2_start_time: null,
          duty2_end_time: null,
        };
      });
      state.saveCandidate.staff_work_schedules = staffWorkSchedules;
      state.saveCandidate.macro_operation_master_id = 0;
      const staff_skills = [...Array(5)].map((_, i) => {
        return { priority: i + 1, timetable_master_id: 0 };
      });

      state.saveCandidate.staff_skills = staff_skills;
      if (budgetGroupIds.length === 1) {
        state.filteredTimetableMasters = state.timetableMasters.filter(
          (timetableMaster) => timetableMaster.budget_group_id === budgetGroupIds[0],
        );

        state.macroOperationMasters = await macroOperationMasterApi.index({
          workplaceId: workplaceId.value,
          budgetGroupId: budgetGroupIds[0],
          params: {
            is_enabled: true,
          },
        });
        state.filteredMacroOperationMasters = state.macroOperationMasters;
      }
    }

    async function prepareUpdate(item: StaffDisp) {
      state.saveCandidate.saveType = 'update';
      state.filteredTimetableMasters = state.timetableMasters.filter(
        (timetableMaster) => timetableMaster.budget_group_id === item.budget_group_id,
      );

      state.macroOperationMasters = await macroOperationMasterApi.index({
        workplaceId: workplaceId.value,
        budgetGroupId: item.budget_group_id,
        params: {
          is_enabled: true,
        },
      });
      state.filteredMacroOperationMasters = state.macroOperationMasters.filter(
        (macroOperationMaster) => macroOperationMaster.budget_group_id === item.budget_group_id,
      );

      const staff_skills = [...Array(5)].map((_, i) => {
        const staff_skill = findStaffSkill(item.staff_skills, i + 1);
        return { priority: i + 1, timetable_master_id: staff_skill?.timetable_master_id ?? null };
      });

      const saveCandidate: SaveCandidate = {
        ...item.staff_extension!,
        saveType: 'update',
        staff_skills: staff_skills,
        is_cleared: [],
        staff_ids: [],
      };

      const staffWorkScheduleFormValues: StaffWorkScheduleFormValues[] = [];
      week.forEach((dayOfWeek) => {
        const currentStaffWorkSchedule = saveCandidate.staff_work_schedules?.find(
          (obj) => obj.day_of_week === dayOfWeek.value,
        );
        const staffWorkSchedule = currentStaffWorkSchedule ?? {
          day_of_week: dayOfWeek.value,
          work_start_time: null,
          work_end_time: null,
          break1_start_time: null,
          break1_end_time: null,
          break2_start_time: null,
          break2_end_time: null,
          duty1_macro_operation_master_id: null,
          duty1_start_time: null,
          duty1_end_time: null,
          duty2_macro_operation_master_id: null,
          duty2_start_time: null,
          duty2_end_time: null,
        };
        staffWorkScheduleFormValues.push(convertToStaffWorkScheduleFormValues(staffWorkSchedule));
        if (currentStaffWorkSchedule === undefined) {
          if (saveCandidate.staff_work_schedules === undefined) {
            saveCandidate.staff_work_schedules = [];
          }
          saveCandidate.staff_work_schedules.push(staffWorkSchedule);
        }
      });

      state.staffWorkScheduleFormValues = staffWorkScheduleFormValues;
      state.saveCandidate = saveCandidate;
      state.selectedStaff = item;
    }

    function attachValidate() {
      week.forEach((dayOfWeek) => {
        vueInstance.$validator.attach({
          name: dayOfWeek.key,
          rules: 'custom_after2',
          getter: () => {
            const staffWorkSchedule = state.saveCandidate.staff_work_schedules?.find((obj) => {
              return obj.day_of_week === dayOfWeek.value;
            });
            if (staffWorkSchedule === undefined) {
              return;
            }
            return {
              start_time: staffWorkSchedule.work_start_time,
              end_time: staffWorkSchedule.work_end_time,
              break1_start_time: staffWorkSchedule.break1_start_time,
              break1_end_time: staffWorkSchedule.break1_end_time,
              break2_start_time: staffWorkSchedule.break2_start_time,
              break2_end_time: staffWorkSchedule.break2_end_time,
            };
          },
        });
        vueInstance.$validator.attach({
          name: `${dayOfWeek.key}Duty1Time`,
          rules: 'custom_after1',
          getter: () => {
            const staffWorkSchedule = state.saveCandidate.staff_work_schedules?.find((obj) => {
              return obj.day_of_week === dayOfWeek.value;
            });
            if (staffWorkSchedule === undefined) {
              return;
            }
            return {
              start_time: staffWorkSchedule.duty1_start_time,
              end_time: staffWorkSchedule.duty1_end_time,
              disallow_equal: true,
            };
          },
        });
        vueInstance.$validator.attach({
          name: `${dayOfWeek.key}Duty2Time`,
          rules: 'custom_after1',
          getter: () => {
            const staffWorkSchedule = state.saveCandidate.staff_work_schedules?.find((obj) => {
              return obj.day_of_week === dayOfWeek.value;
            });
            if (staffWorkSchedule === undefined) {
              return;
            }
            return {
              start_time: staffWorkSchedule.duty2_start_time,
              end_time: staffWorkSchedule.duty2_end_time,
              disallow_equal: true,
            };
          },
        });
      });
    }

    function closeSaveModal() {
      state.saveCandidate = initialSaveCandidateState();
      state.staffWorkScheduleFormValues = [];
      clearErrors();
      detachValidate();
      state.showSaveModal = false;
      state.allSelectedItems = false;
      state.selectedStaff = null;
      state.isMultipleBudgetGroupsTargeted = false;
      selectedShifts.value = [];
      loadStaffs();
    }

    function detachValidate() {
      week.forEach((day) => {
        vueInstance.$validator.detach(day.key);
        vueInstance.$validator.detach(`${day.key}Duty1Time`);
        vueInstance.$validator.detach(`${day.key}Duty2Time`);
      });
    }
    // 保存
    async function _saveItem() {
      if (!(await vvValidate(vueInstance))) {
        return;
      }

      if (state.saveCandidate.saveType === 'update') {
        await doStaffExtensionUpdate();
      } else {
        await doStaffExtensionBulkUpdate();
      }
    }

    // update
    async function doStaffExtensionUpdate(): Promise<void> {
      const operation: Operation = 'update';
      try {
        if (state.saveCandidate.staff_id === null || state.saveCandidate.staff_type === null) {
          return;
        }
        type ConfirmedStaffSkill = { timetable_master_id: number; priority: number };
        const staffSkills: ConfirmedStaffSkill[] = state.saveCandidate.staff_skills.filter(
          (staffSkill): staffSkill is ConfirmedStaffSkill => staffSkill.timetable_master_id !== null,
        );

        await staffExtensionApi.update({
          workplaceId: workplaceId.value,
          data: {
            ...state.saveCandidate,
            staff_id: state.saveCandidate.staff_id,
            staff_skills: staffSkills,
            staff_type: state.saveCandidate.staff_type || STAFF_TYPE.REGULAR,
            is_shift_management_target: state.saveCandidate.is_shift_management_target ?? false,
            is_appropriation_target: state.saveCandidate.is_appropriation_target ?? false,
            macro_operation_master_id: state.saveCandidate.macro_operation_master_id ?? null,
            gender: state.saveCandidate.gender || STAFF_GENDER.NO_ANSWER,
            is_key_player: state.saveCandidate.is_key_player || false,
            is_forkman: state.saveCandidate.is_forkman || false,
          },
        });
        await loadStaffs({ resetsCurrentPage: false });
        closeSaveModal();
        notifySuccess1(vueInstance, `${displayPageName}を編集しました`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'dup_timetable_master') {
          const msg = '同じ担当工程は選択できません。';
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else {
          notifyCommonError(operation, err);
        }
      }
    }

    // bulk update
    async function doStaffExtensionBulkUpdate(): Promise<void> {
      const operation: Operation = 'bulkUpdate';
      const bulkUpdateCount = state.saveCandidate.staff_ids.length;
      try {
        const macroOperationMasterId =
          state.saveCandidate.macro_operation_master_id !== MACRO_OPERATION_MASTER_ID_NO_CHANGE
            ? state.saveCandidate.macro_operation_master_id
            : undefined;
        const tmpStaffSkills = state.saveCandidate.staff_skills.filter(
          (obj) => obj.timetable_master_id !== MACRO_OPERATION_MASTER_ID_NO_CHANGE,
        );
        const staffSkills = tmpStaffSkills.length !== 0 ? tmpStaffSkills : undefined;

        const staffWorkSchedules: StaffWorkScheduleBulkUpdateParams[] = state.saveCandidate.staff_work_schedules.map(
          (staffWorkSchedule) => {
            const dayOfWeek = getDayOfWeek(staffWorkSchedule.day_of_week);
            const clearTimes = dayOfWeek && state.saveCandidate.is_cleared.find((v) => v === dayOfWeek.key);
            const workStartTime =
              clearTimes || staffWorkSchedule.work_start_time !== null ? staffWorkSchedule.work_start_time : undefined;
            const workEndTime =
              clearTimes || staffWorkSchedule.work_end_time !== null ? staffWorkSchedule.work_end_time : undefined;
            const break1StartTime =
              clearTimes || staffWorkSchedule.break1_start_time !== null
                ? staffWorkSchedule.break1_start_time
                : undefined;
            const break1EndTime =
              clearTimes || staffWorkSchedule.break1_end_time !== null ? staffWorkSchedule.break1_end_time : undefined;
            const break2StartTime =
              clearTimes || staffWorkSchedule.break2_start_time !== null
                ? staffWorkSchedule.break2_start_time
                : undefined;
            const break2EndTime =
              clearTimes || staffWorkSchedule.break2_end_time !== null ? staffWorkSchedule.break2_end_time : undefined;

            const staffWorkScheduleBulkUpdateParams: StaffWorkScheduleBulkUpdateParams = {
              day_of_week: staffWorkSchedule.day_of_week,
              work_start_time: workStartTime,
              work_end_time: workEndTime,
              break1_start_time: break1StartTime,
              break1_end_time: break1EndTime,
              break2_start_time: break2StartTime,
              break2_end_time: break2EndTime,
            };
            if (staffWorkSchedule.duty1_macro_operation_master_id !== MACRO_OPERATION_MASTER_ID_NO_CHANGE) {
              Object.assign(staffWorkScheduleBulkUpdateParams, {
                duty1_macro_operation_master_id: staffWorkSchedule.duty1_macro_operation_master_id,
                duty1_start_time: staffWorkSchedule.duty1_start_time,
                duty1_end_time: staffWorkSchedule.duty1_end_time,
              });
            }
            if (staffWorkSchedule.duty2_macro_operation_master_id !== MACRO_OPERATION_MASTER_ID_NO_CHANGE) {
              Object.assign(staffWorkScheduleBulkUpdateParams, {
                duty2_macro_operation_master_id: staffWorkSchedule.duty2_macro_operation_master_id,
                duty2_start_time: staffWorkSchedule.duty2_start_time,
                duty2_end_time: staffWorkSchedule.duty2_end_time,
              });
            }
            return staffWorkScheduleBulkUpdateParams;
          },
        );

        await staffExtensionApi.bulkUpdate({
          workplaceId: workplaceId.value,
          data: {
            staff_type: state.saveCandidate.staff_type ?? undefined,
            is_shift_management_target: state.saveCandidate.is_shift_management_target ?? undefined,
            is_appropriation_target: state.saveCandidate.is_appropriation_target ?? undefined,
            macro_operation_master_id: macroOperationMasterId,
            gender: state.saveCandidate.gender ?? undefined,
            is_key_player: state.saveCandidate.is_key_player ?? undefined,
            is_forkman: state.saveCandidate.is_forkman ?? undefined,
            has_custom_skill1: state.saveCandidate.has_custom_skill1 ?? undefined,
            has_custom_skill2: state.saveCandidate.has_custom_skill2 ?? undefined,
            has_custom_skill3: state.saveCandidate.has_custom_skill3 ?? undefined,
            staff_skills: staffSkills,
            staff_work_schedules: staffWorkSchedules,
            staff_ids: state.saveCandidate.staff_ids,
          },
        });
        await loadStaffs({ resetsCurrentPage: false });
        closeSaveModal();
        notifySuccess1(vueInstance, `${displayPageName}を${bulkUpdateCount}件一括編集しました`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'dup_timetable_master') {
          const msg = '同じ担当工程は選択できません。';
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else {
          notifyCommonError(operation, err);
        }
      }
    }

    function notifyCommonError(operation: Operation, err?: Error) {
      const msg =
        `${displayPageName}の${msgVars[operation]}に失敗しました。` +
        '管理者に連絡してください。' +
        `(ERR: ${displayPageName} ${errIds[operation]}, user_id:${userId.value})`;
      notifyError1(vueInstance, msg, { err });
    }

    function toggleAllSelectedItems() {
      state.allSelectedItems = state.saveCandidate.staff_ids.length === state.staffs.length;
    }

    function toggleSelectedItems(currentPageElements: StaffDisp[]) {
      // reset
      state.saveCandidate = initialSaveCandidateState();
      if (state.allSelectedItems) {
        // check all
        currentPageElements.forEach((e) => {
          state.saveCandidate.staff_ids.push(e.id);
        });
      }
    }

    function toggleItems(id: number) {
      const index = state.saveCandidate.staff_ids.findIndex((e) => e === id);
      if (index < 0) {
        state.saveCandidate.staff_ids.push(id);
      } else {
        state.saveCandidate.staff_ids.splice(index, 1);
      }
      toggleAllSelectedItems();
    }

    function isSaveCandidateSelected(id: number) {
      return state.saveCandidate.staff_ids.findIndex((e) => e === id) >= 0;
    }

    function getDayOfWeek(num: number) {
      return week.find((day) => day.value === num);
    }

    function clearInputTimes(weekKey: WeekKey) {
      selectedShifts.value = selectedShifts.value.filter((v) => v !== weekKey);
      state.saveCandidate.staff_work_schedules?.forEach((obj) => {
        const dayOfWeek = getDayOfWeek(obj.day_of_week);
        if (dayOfWeek && dayOfWeek.key === weekKey) {
          obj.work_start_time = null;
          obj.work_end_time = null;
          obj.break1_start_time = null;
          obj.break1_end_time = null;
          obj.break2_start_time = null;
          obj.break2_end_time = null;
        }
      });
      state.staffWorkScheduleFormValues.forEach((times) => {
        const dayOfWeek = getDayOfWeek(times.day_of_week);
        if (dayOfWeek && dayOfWeek.key === weekKey) {
          times.work_start_time_hour = null;
          times.work_start_time_min = null;
          times.work_end_time_hour = null;
          times.work_end_time_min = null;
          times.break1_start_time_hour = null;
          times.break1_start_time_min = null;
          times.break1_end_time_hour = null;
          times.break1_end_time_min = null;
          times.break2_start_time_hour = null;
          times.break2_start_time_min = null;
          times.break2_end_time_hour = null;
          times.break2_end_time_min = null;
        }
      });
      // クリアした列のバリデートをリセット
      vueInstance.$validator.detach(weekKey);
      vvReset(vueInstance, `${weekKey}WorkStartTimeHour`);
      vvReset(vueInstance, `${weekKey}WorkStartTimeMin`);
      vvReset(vueInstance, `${weekKey}WorkEndTimeHour`);
      vvReset(vueInstance, `${weekKey}WorkEndTimeMin`);
      vvReset(vueInstance, `${weekKey}Break1StartTimeHour`);
      vvReset(vueInstance, `${weekKey}Break1StartTimeMin`);
      vvReset(vueInstance, `${weekKey}Break1EndTimeHour`);
      vvReset(vueInstance, `${weekKey}Break1EndTimeMin`);
      vvReset(vueInstance, `${weekKey}Break2StartTimeHour`);
      vvReset(vueInstance, `${weekKey}Break2StartTimeMin`);
      vvReset(vueInstance, `${weekKey}Break2EndTimeHour`);
      vvReset(vueInstance, `${weekKey}Break2EndTimeMin`);
      attachValidate();
    }
    // スタッフ選択済みかどうか
    function hasStaff(): boolean {
      return state.staffs.length > 0;
    }

    // スタッフスキル検索
    function findStaffSkill(staffSkills: StaffSkill[], num: number): StaffSkill | undefined {
      return staffSkills.find((staffSkill: StaffSkill) => {
        return staffSkill.priority === num;
      });
    }

    // タイムテーブルマスタ検索
    function findTimetableMaster(timetableMasterId: number): TimetableMaster | undefined {
      return state.timetableMasters.find((timetableMaster) => {
        return timetableMaster.id === timetableMasterId;
      });
    }

    // 性別名表示
    const getGenderName = (genderNum: StaffGender) => {
      return StaffGenderSelectOptions.find((gender) => gender.id === genderNum)?.name ?? '';
    };

    // スタッフタイプ名表示
    const getStaffTypeName = (staffTypeNum: StaffType) => {
      return StaffTypeSelectOptions.find((staffType) => staffType.id === staffTypeNum)?.name ?? '';
    };

    async function onTimeChange(key: WeekKey, name: LabelName) {
      const dayOfWeek = getDayValue(key);
      if (dayOfWeek < 0) {
        return;
      }

      state.saveCandidate.staff_work_schedules?.forEach((staff_work_schedule) => {
        const formValue = state.staffWorkScheduleFormValues.find((formValue) => formValue.day_of_week === dayOfWeek);
        if (formValue && staff_work_schedule.day_of_week === dayOfWeek) {
          staff_work_schedule[name] = packToTimeIntegerWithGuard(
            formValue[`${name}_hour`],
            formValue[`${name}_min`],
            0,
          );
        }
      });

      if (name.match('duty1')) {
        vvValidate(vueInstance, `${key}Duty1Time`);
      } else if (name.match('duty2')) {
        vvValidate(vueInstance, `${key}Duty2Time`);
      } else {
        vvValidate(vueInstance, key);
      }
    }

    onMounted(async () => {
      setPageName(vueInstance, displayPageName);
      await getList();
    });

    onUnmounted(() => {
      detachValidate();
    });

    const gatedFuncGenerator = getGatedFuncGenerator();

    const selectedShifts = ref<WeekKey[]>([]);

    const { shiftPatterns, showsShiftPatternSelectModal, showShiftPatternSelectModal, hideShiftPatternSelectModal } =
      useShiftPatternSelectModal();
    const handleClickShiftPatternSettingButton = () => {
      const budgetGroup = (() => {
        if (state.saveCandidate.saveType === 'bulkUpdate') {
          if (state.isMultipleBudgetGroupsTargeted) {
            return null;
          }
          // isMultipleBudgetGroupsTargetedがfalseの場合、選択されているスタッフは全て同じ管理グループなので、該当する最初のスタッフの管理グループを返す
          return state.staffs.find((v) => state.saveCandidate.staff_ids.includes(v.id))?.budget_group ?? null;
        }
        return state.selectedStaff?.budget_group ?? null;
      })();

      if (budgetGroup !== null) {
        showShiftPatternSelectModal(budgetGroup);
      }
    };
    const handleSelectShiftPattern = (shiftPattern: ShiftPattern) => {
      updateCandidateByShiftPattern(
        state.saveCandidate,
        state.staffWorkScheduleFormValues,
        selectedShifts.value,
        shiftPattern,
      );
    };
    const selectTimetableMasters = () => {
      const budgetGroup = searchParams.budgetGroup;
      state.selectedTimetableMasters =
        budgetGroup !== null
          ? state.timetableMasters.filter((timetableMaster) => timetableMaster.budget_group_id === budgetGroup.id)
          : state.timetableMasters;
    };

    const selectMacroOperationMasters = () => {
      const budgetGroup = searchParams.budgetGroup;
      state.selectedMacroOperationMasters =
        budgetGroup !== null
          ? state.macroOperationMasters.filter(
              (macroOperationMaster) => macroOperationMaster.budget_group_id === budgetGroup.id,
            )
          : state.macroOperationMasters;
    };
    const selectStaffMasters = () => {
      const budgetGroup = searchParams.budgetGroup;
      state.selectedStaffs =
        budgetGroup !== null
          ? state.staffMasters.filter((staffMaster) => staffMaster.budget_group_id === budgetGroup.id)
          : state.staffMasters;
    };
    // 曜日の頭文字を大文字に変更して、曜日別出退勤時間のソートKeyを作成
    function buildDowWorkTimeSortKey(dow: WeekKey): string {
      return `sort${dow.charAt(0).toUpperCase() + dow.slice(1).toLowerCase()}WorkTime`;
    }

    const goToStaffOverview = (item: StaffDisp) => {
      root.$router.push({
        name: 'WorkplaceStaffOverview',
        query: { budget_group_id: String(item.budget_group_id), staff_id: String(item.id) },
      });
    };

    useCsvUploadResultModalProvider();
    const { uploadStaffCsv } = useUploadStaffCsv({
      workplaceId,
      fetchStaffs: () => loadStaffs({ resetsCurrentPage: false }),
    });

    const isMemoInvalid = (memo: string | null | undefined): boolean => {
      return (memo?.length ?? 0) > MEMO_MAX_LENGTH;
    };

    watch(
      () => state.saveCandidate.is_shift_management_target,
      (isShiftManagementTarget) => {
        if (isShiftManagementTarget === false) {
          week.forEach(({ key }) => clearInputTimes(key));
          state.saveCandidate.is_cleared = [];
        }
      },
    );

    const canInputTime = (weekKey: WeekKey) => {
      return (
        !state.saveCandidate.is_cleared.includes(weekKey) && state.saveCandidate.is_shift_management_target !== false
      );
    };

    return {
      state,
      searchParams,
      pagination,
      hasError,
      getError,
      clearErrors,
      onChangeDutyMacroOperationMaster,
      loadStaffs,
      getStaffsWithUpdatedSearchParams,
      openUpdateModal,
      openBulkUpdateModal,
      closeSaveModal,
      saveItem: gatedFuncGenerator.makeAsyncFuncGated(_saveItem),
      toggleAllSelectedItems,
      toggleSelectedItems,
      toggleItems,
      isSaveCandidateSelected,
      defaultSortSpec,
      hasStaff,
      findStaffSkill,
      findTimetableMaster,
      StaffGenderSelectOptions,
      StaffTypeSelectOptions,
      week,
      formatDate,
      formatTimeInteger,
      buildDowWorkTimeSortKey,
      getGenderName,
      getStaffTypeName,
      onTimeChange,
      initializeSearchStaffsParams,
      isExist,
      clearInputTimes,
      selectedShifts,
      shiftPatterns,
      showsShiftPatternSelectModal,
      hideShiftPatternSelectModal,
      handleClickShiftPatternSettingButton,
      handleSelectShiftPattern,
      goToStaffOverview,
      uploadStaffCsv,
      isMemoInvalid,
      canInputTime,
    };
  },
});
