










import { ref, defineComponent, SetupContext, PropType } from '@vue/composition-api';
import Encoding from 'encoding-japanese';
import { CsvRow, BeforeDownload } from './types';

export default defineComponent({
  name: 'csv-download',
  props: {
    csvData: {
      type: Array as PropType<CsvRow[]>,
      required: true,
    },
    csvFileName: {
      type: String,
      required: true,
    },
    beforeDownload: {
      type: Function as PropType<BeforeDownload>,
      required: true,
    },
    enclosedByDoubleQuotation: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context: SetupContext) {
    const isLoading = ref(false);
    async function downloadCsv() {
      try {
        isLoading.value = true;
        // not using emit to handle promise!
        await props.beforeDownload();
        if (props.csvData.length === 0) {
          return;
        }
        let csvContents: string = '';
        props.csvData.forEach((r) => {
          let line: string = '';
          r.forEach((c) => {
            line += props.enclosedByDoubleQuotation ? `"${c}",` : `${c},`;
          });
          // remove last ',' before adding line change code
          csvContents += line.slice(0, -1) + '\r\n';
        });
        // remove last line change
        csvContents = csvContents.trim();
        // https://note.com/nowbuilding/n/n05702862f575
        const convertedToUnicode = Encoding.stringToCode(csvContents);
        const convertedToSjis = Encoding.convert(convertedToUnicode, 'SJIS', 'UNICODE');
        const convertedToBinary = new Uint8Array(convertedToSjis);
        const blob = new Blob([convertedToBinary], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = props.csvFileName;
        link.click();
      } finally {
        isLoading.value = false;
      }
    }
    return {
      isLoading,
      downloadCsv,
    };
  },
});
