import Vue from 'vue';
import { addComma, addCommaFraction } from 'src/filters/number_filters';
import { addYears, differenceInYears, subYears, format, addDays, differenceInDays } from 'date-fns';
import { SetupContext, computed, defineComponent, nextTick, onMounted, reactive, ref, set } from '@vue/composition-api';
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group';
import timetableTrendApi from 'src/apis/timetableTrend';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import TimeRangeInput from 'src/components/Workplace/TimeRangeInput/index.vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { TimetableTrendIndexResult } from 'src/models/timetableTrend';
import { packToTimeInteger, unpackTimeInteger } from 'src/util/datetime';
import { isExist } from 'src/util/isExist';
import {
  createQuantityCountInputFromApiResponse,
  createTotalValueFromApiResponse,
  createResultTimeCalcInputFromApiResponse,
  createTimetablesFromApiResponse,
  createProductivityCalcInputFromApiResponse,
  createQuantityCalcInputFromApiResponse,
} from './timetableTrendHelper';
import {
  SearchedParamsState,
  TimetableTrendOverviewState,
  TimetableTrendState,
  SelectedParamsState,
  EnabledListParamsState,
} from './types';
import {
  TIMELINE_COLUMN_WIDTH,
  DISPLAY_HOUR_PERIOD,
  BLOCK_LENGTH_PER_HOUR,
  WEEK_DAY,
  DAY_LABELS,
  MAX_SEARCH_YEAR_RANGE,
} from './const';
import { ProgressHeader } from 'src/models/progressHeader';
import { TimelineIndexResult } from 'src/models/timeline';
import { useUrlParameters } from 'src/composables/useUrlParameters';

type UrlParameters = {
  budget_group_id?: number;
  timetable_master_id?: number;
  display?: 1;
};

const setupState = (context: SetupContext): TimetableTrendState => {
  const root = context.root as Vue;

  // 初期セットアップ　タイムタイン関連で使用するState
  const state: TimetableTrendState = reactive({
    ...wrappedMapGetters(root.$store, 'workplace', ['workplaceExtension']),
    calendar: [],
    timetables: [],
    displayTimetables: [],
    workplaceId: root.$route.params.workplaceId,
    totalHeadcountHourBlocks: [],
    displayTimes: new Array(DISPLAY_HOUR_PERIOD).fill(0).map((startHour, i) => `${startHour + i}:00`),
    targetStartTime: 0,
    targetEndTime: packToTimeInteger(24, 0, 0),
    lastMemo: '',
  });
  return state;
};
// 初期セットアップ　選択肢保持用のState
const setupEnabledListParamsState = (selectedParamsState: SelectedParamsState) => {
  const enabledListParamsState: EnabledListParamsState = reactive({
    budgetGroups: [],
    timetableMasters: [],
    filteredTimetableMasters: computed(() => {
      let filteredTimetableMasters = enabledListParamsState.timetableMasters;
      if (selectedParamsState.budgetGroupId) {
        filteredTimetableMasters = enabledListParamsState.timetableMasters.filter(
          (timetableMaster) => timetableMaster.budget_group_id === selectedParamsState.budgetGroupId,
        );
      }
      return filteredTimetableMasters;
    }),
    dayLabels: DAY_LABELS,
  });
  return enabledListParamsState;
};
// 初期セットアップ　（画面表示用）検索結果保持用のState
const setupSearchedParamsState = () => {
  const searchedParamsState: SearchedParamsState = reactive({
    timetableMaster: null,
    startDate: new Date(),
    endDate: new Date(),
    displayDays: '',
  });
  return searchedParamsState;
};
// 初期セットアップ　（ロジック用）検索結果保持用のState
const setupSelectedParamsState = () => {
  const today = new Date();
  const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const selectedParamsState: SelectedParamsState = reactive({
    timetableMasterId: null,
    budgetGroupId: null,
    startDate: startDate,
    endDate: today,
    selectedDayLabels: [],
    searchButtonDisabled: false,
    selectedDays: computed(() =>
      selectedParamsState.selectedDayLabels
        .slice()
        .sort((a, b) => a - b)
        .map((dayLabel) => WEEK_DAY[dayLabel])
        .join(', '),
    ),
  });
  return selectedParamsState;
};
// 初期セットアップ　合計パネル用のState
const setupOverviewState = () => {
  const overviewState: TimetableTrendOverviewState = reactive({
    headcountTotal: {
      quantity: 0,
      manHours: 0,
      productivity: 0,
    },
    quantityCalc: {
      average: 0,
      median: 0,
      max: 0,
      min: 0,
    },
    productivityCalc: {
      max: 0,
      min: 0,
    },
    quantityCount: {
      total: 0,
      startDelay: 0,
      endDelay: 0,
    },
    resultTime: {
      scheduleStart: '',
      scheduleEnd: '',
      resultAlreadyStart: '',
      resultAlreadyEnd: '',
      resultLatestStart: '',
      resultLatestEnd: '',
    },
  });
  return overviewState;
};

export default defineComponent({
  components: {
    TimeRangeInput,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, '工程 特性');
    const state = setupState(context);
    const selectedParamsState = setupSelectedParamsState();
    const searchedParamsState = setupSearchedParamsState();
    const enabledListParamsState = setupEnabledListParamsState(selectedParamsState);
    const overviewState = setupOverviewState();
    const timetableStyle = {
      gridTemplateColumns: `repeat(${DISPLAY_HOUR_PERIOD}, ${TIMELINE_COLUMN_WIDTH}px)`,
      display: 'grid',
    };
    const tablesRef = ref<HTMLDivElement>();
    const urlParameters = useUrlParameters<UrlParameters>();

    onMounted(async () => {
      await ensureUserAndMasters(context);
      enabledListParamsState.budgetGroups = await budgetGroupApi.index(state.workplaceId);
      const timetableMasters = await timetableMasterApi.index({
        workplaceId: state.workplaceId,
        params: { is_enabled: true, use_in_review_phase: true },
      });
      enabledListParamsState.timetableMasters = timetableMasters;

      const {
        budget_group_id: budgetGroupId,
        timetable_master_id: timetableMasterId,
        display,
      } = urlParameters.getUrlParameters();

      // timetable_master_id が指定され、対応する TimetableMaster が存在するとき
      //   - budgetGroupId の選択値は、対応する TimetableMaster の所属する budgetGroup の id となる
      //   - timetableMasterId の選択値は、指定された timetable_master_id の値となる
      const timetableMaster = enabledListParamsState.timetableMasters.find(
        (timetableMaster) => timetableMaster.id === timetableMasterId,
      );
      if (timetableMaster !== undefined) {
        selectedParamsState.budgetGroupId = timetableMaster.budget_group_id;
        selectedParamsState.timetableMasterId = timetableMaster.id;
        if (display === 1) {
          onDisplayButtonClick();
        }
        return;
      }

      // TimetableMaster が存在する timetable_master_id が指定されず、budget_group_id が指定され、対応する BudgetGroup が存在するとき
      //   - budgetGroupIdの 選択値は、指定された budget_group_id の値となる
      //   - timetableMasterId の選択値は、対応する BudgetGroup に所属する timetableMaster の内、先頭（disp_oder が最小のもの）の id となる
      const budgetGroup = enabledListParamsState.budgetGroups.find((budgetGroup) => budgetGroup.id === budgetGroupId);
      if (budgetGroup !== undefined) {
        selectedParamsState.budgetGroupId = budgetGroup.id;
        selectFirstTimetableMasterIdInSelectedBudgetGroup();
        if (display === 1) {
          onDisplayButtonClick();
        }
        return;
      }

      // データが存在する timetable_master_id と budget_group_id が指定されなかったとき
      //   - budgetGroupIdの 選択値は、有効な BudgetGroup の内、先頭（disp_oder が最小のもの）の id となる
      //   - timetableMasterId の選択値は、上記で選ばれた BudgetGroup に所属する timetableMaster の内、先頭（disp_oder が最小のもの）の id となる
      selectedParamsState.budgetGroupId = enabledListParamsState.budgetGroups[0]?.id;
      selectFirstTimetableMasterIdInSelectedBudgetGroup();
      if (display === 1) {
        onDisplayButtonClick();
      }
    });

    const changeURLQuery = async () => {
      const query = {
        budget_group_id: selectedParamsState.budgetGroupId ?? undefined,
        timetable_master_id: selectedParamsState.timetableMasterId ?? undefined,
      };
      await urlParameters.setUrlParameters(query);
    };

    const onDisplayButtonClick = () => {
      changeURLQuery();
      refreshTimetableTrend();
    };

    // API の値で表をリセットする
    const refreshTimetableTrend = async (): Promise<void> => {
      // 検索条件が指定されていない場合は空振り
      if (!selectedParamsState.startDate || !selectedParamsState.endDate) {
        notifyError1(root, '日付が指定されていません。');
        return;
      }
      if (selectedParamsState.startDate > selectedParamsState.endDate) {
        notifyError1(root, '開始日は終了日よりも前の日に設定してください。');
        return;
      }
      if (!selectedParamsState.budgetGroupId) {
        notifyError1(root, '管理グループが指定されていません。');
        return;
      }
      if (!selectedParamsState.timetableMasterId) {
        notifyError1(root, '工程が指定されていません。');
        return;
      }
      selectedParamsState.searchButtonDisabled = true;

      // 画面上の検索日付範囲は最大1年となっており、その範囲内で最大31日分のデータを取得/表示する仕様
      const diffDays = Math.min(differenceInDays(selectedParamsState.endDate, selectedParamsState.startDate), 365) + 1;
      const allDates = Array.from({ length: diffDays }).map((x, i) => addDays(selectedParamsState.startDate, i));
      const calendar =
        selectedParamsState.selectedDayLabels.length === 0
          ? allDates
          : allDates.filter((date) => selectedParamsState.selectedDayLabels.includes(date.getDay()));
      const targetDates = calendar.slice(0, Math.min(diffDays, 31));

      let timetableTrendResponse: TimetableTrendIndexResult;
      try {
        timetableTrendResponse = await timetableTrendApi.timetableTrendIndex({
          workplace_id: state.workplaceId,
          budget_group_id: selectedParamsState.budgetGroupId,
          timetable_master_id: selectedParamsState.timetableMasterId,
          target_dates: targetDates,
        });
      } catch (err: any) {
        notifyError1(root, '表示情報の取得に失敗しました', { err });
        return;
      } finally {
        selectedParamsState.searchButtonDisabled = false;
      }

      // 検索条件の更新
      updateSearchedParamsState();
      // 一覧の更新
      updateState(timetableTrendResponse, targetDates);
      // 合計欄の更新
      updateOverViewState(timetableTrendResponse);

      nextTick(() => {
        if (isExist(tablesRef.value)) {
          state.targetStartTime = searchedParamsState.timetableMaster?.start_time ?? 0;
          const [startHour] = unpackTimeInteger(state.targetStartTime);
          tablesRef.value.scrollLeft = startHour * TIMELINE_COLUMN_WIDTH;
        }
      });
    };

    const updateState = (timetableTrendResponse: TimelineIndexResult, targetDates: Date[]) => {
      if (!selectedParamsState.budgetGroupId || !selectedParamsState.timetableMasterId) {
        return;
      }

      // テーブル用データ
      state.timetables = createTimetablesFromApiResponse(
        timetableTrendResponse,
        BLOCK_LENGTH_PER_HOUR,
        DISPLAY_HOUR_PERIOD,
      );
      state.calendar = targetDates;
      const displayTimetables = [];
      for (let i = 0; i < targetDates.length; i++) {
        const day = targetDates[i];
        const timetable =
          state.timetables.find((obj) => {
            return obj.header.dt.getTime() === day.getTime();
          }) ?? null;

        displayTimetables[day.getTime()] = timetable;
      }
      state.displayTimetables = displayTimetables;
    };
    // サマリ欄の情報を更新する
    const updateOverViewState = (timetableTrendResponse: TimetableTrendIndexResult) => {
      overviewState.headcountTotal = createTotalValueFromApiResponse(timetableTrendResponse);
      overviewState.quantityCalc = createQuantityCalcInputFromApiResponse(timetableTrendResponse);
      overviewState.productivityCalc = createProductivityCalcInputFromApiResponse(timetableTrendResponse);
      overviewState.quantityCount = createQuantityCountInputFromApiResponse(
        timetableTrendResponse,
        searchedParamsState,
      );
      overviewState.resultTime = createResultTimeCalcInputFromApiResponse(timetableTrendResponse, searchedParamsState);
    };
    // 検索結果表示用にStateを更新する
    const updateSearchedParamsState = () => {
      const searchedTimetableMaster = enabledListParamsState.timetableMasters.find((obj) => {
        return obj.id === selectedParamsState.timetableMasterId;
      });
      searchedParamsState.timetableMaster = searchedTimetableMaster ?? null;
      searchedParamsState.startDate = selectedParamsState.startDate;
      searchedParamsState.endDate = selectedParamsState.endDate;
      searchedParamsState.displayDays = selectedParamsState.selectedDays;
    };
    // メモにフォーカス
    const focusMemo = (progressHeader: ProgressHeader) => {
      state.lastMemo = progressHeader.memo;
    };
    // メモの更新
    const saveMemo = async (progressHeader: ProgressHeader) => {
      if (!selectedParamsState.budgetGroupId) {
        return;
      }
      if (!selectedParamsState.timetableMasterId) {
        return;
      }
      if (progressHeader.memo === state.lastMemo) {
        return;
      }

      try {
        await timetableTrendApi.upsert({
          workplace_id: state.workplaceId,
          budget_group_id: selectedParamsState.budgetGroupId,
          timetable_master_id: selectedParamsState.timetableMasterId,
          dt: progressHeader.dt,
          memo: progressHeader.memo,
        });
        notifySuccess1(root, 'メモを更新しました');
      } catch (err: any) {
        notifyError1(root, 'メモの更新に失敗しました', { err });
      }
    };
    function onStartDateChange() {
      if (selectedParamsState.startDate > selectedParamsState.endDate) {
        notifyError1(root, '開始日を終了日よりも前の日に指定してください');
        selectedParamsState.searchButtonDisabled = true;
        return;
      } else if (isDateRangeMaxExceeded()) {
        selectedParamsState.endDate = addYears(selectedParamsState.startDate, 1);
      }
      selectedParamsState.searchButtonDisabled = false;
    }

    function onEndDateChange() {
      if (selectedParamsState.startDate > selectedParamsState.endDate) {
        notifyError1(root, '開始日を終了日よりも前の日に指定してください');
        selectedParamsState.searchButtonDisabled = true;
        return;
      } else if (isDateRangeMaxExceeded()) {
        selectedParamsState.startDate = subYears(selectedParamsState.endDate, 1);
      }
      selectedParamsState.searchButtonDisabled = false;
    }

    function isDateRangeMaxExceeded() {
      return differenceInYears(selectedParamsState.endDate, selectedParamsState.startDate) >= MAX_SEARCH_YEAR_RANGE;
    }

    const formatNumber = (num: number) => {
      return parseFloat(num.toFixed(1));
    };

    const toFixed = (num: number | null | undefined, digits: number): string => {
      if (!isExist(num)) {
        return '';
      }
      return num.toFixed(digits);
    };

    const selectFirstTimetableMasterIdInSelectedBudgetGroup = () => {
      selectedParamsState.timetableMasterId = null;
      if (selectedParamsState.budgetGroupId && enabledListParamsState.filteredTimetableMasters.length > 0) {
        selectedParamsState.timetableMasterId = enabledListParamsState.filteredTimetableMasters[0].id;
      }
    };

    return {
      state,
      overviewState,
      searchedParamsState,
      selectedParamsState,
      enabledListParamsState,
      timetableStyle,
      tablesRef,
      addComma,
      addCommaFraction,
      onStartDateChange,
      onEndDateChange,
      onDisplayButtonClick,
      formatNumber,
      format,
      focusMemo,
      saveMemo,
      DAY_LABELS,
      selectFirstTimetableMasterIdInSelectedBudgetGroup,
      toFixed,
      isExist,
    };
  },
});
