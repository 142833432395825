// TODO ディレクトリ名を MonthlyBoard に変更予定
import Vue from 'vue';
import { defineComponent, SetupContext, computed, ref, reactive, onUpdated } from '@vue/composition-api';
import { ShowModeOptions, HEADCOUNT_MODE, HeadcountModeOptions } from 'src/consts';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { isExist } from 'src/util/isExist';
import { addComma } from 'src/filters/number_filters';
import { TaskMaster } from './types';
import { addDays, differenceInDays, format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import NumberInput from './NumberInput/index.vue';
import ActualDataFetchModal from './ActualDataFetchModal/index.vue';
import { DisplayTargetFilter } from './DisplayTargetFilter';
import { DisplayDataCsvDownloadButton } from './DisplayDataCsvDownloadButton';
import { useActualDataFetchModal, useActualDataFetchModalProvider } from './composables/useActualDataFetchModal';
import { useSearchConditions, useSearchConditionsProvider } from './composables/useSearchConditions';
import { useDisplayConditions, useDisplayConditionsProvider } from './composables/useDisplayConditions';
import { useMonthlyPlanUrlParametersProvider } from './composables/useMonthlyPlanUrlParameters';
import { useMonthlyPlan, useMonthlyPlanProvider } from './composables/useMonthlyPlan';
import { useMonthlyPlanOverview, useMonthlyPlanOverviewProvider } from './composables/useMonthlyPlanOverview';
import { useBudgetGroups, useBudgetGroupsProvider } from 'src/composables/useBudgetGroups';
import { useInputValidations, useInputValidationsProvider } from './composables/useInputValidations';
import {
  useBudgetGroupPlanBoardMisc,
  useBudgetGroupPlanBoardMiscProvider,
} from './composables/useBudgetGroupPlanBoardMisc';
import { useMacroOperation, useMacroOperationProvider } from './composables/useMacroOperation';
import CsvUploadNeutralButton from 'src/components/UIComponents/Buttons/CsvUploadButton/CsvUploadNeutralButton.vue';
import CsvDownloadNeutralButton from 'src/components/UIComponents/Buttons/CsvDownloadButton/CsvDownloadNeutralButton.vue';
import { useUploadMonthlyPlanCsv } from './composables/useUploadMonthlyPlanCsv';
import { CsvLine } from 'src/util/type_util';

const MEMO_MAX_LENGTH = 255;
const TEXTAREA_DEFAULT_HEIGHT = 75;
type TextareaState = {
  height: number;
};
type CsvState = {
  csvFileName: string;
  csvData: CsvLine[];
};
const formatDayOfWeek = (date: Date) => {
  return format(date, 'E', { locale: ja });
};

const formatYearMonth = (date: Date) => {
  return format(date, 'yy年 M月');
};

const formatValue = (_value?: number, numberOfDecimalPlaces: number = 0) => {
  if (!isExist(_value) || _value.toString().length === 0) {
    return _value;
  }
  const denominator = Math.pow(10, numberOfDecimalPlaces);
  const value = Math.round(_value * denominator);
  // value が -0 だった場合に、addComma(-0) の結果 '-0' という文字列に変換されてしまうのを避けるため value が 0(-0) のときは 0 という三項演算子にしている
  const commaValue = addComma(value === 0 ? 0 : value / denominator);
  return commaValue.includes('.') || numberOfDecimalPlaces === 0 ? commaValue : `${commaValue}.0`;
};
export default defineComponent({
  components: {
    ActualDataFetchModal,
    NumberInput,
    DisplayTargetFilter,
    CsvUploadNeutralButton,
    CsvDownloadNeutralButton,
    DisplayDataCsvDownloadButton,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, '月間ボード');
    const monthlyPlanLabelsTableRef = ref<HTMLDivElement>();
    const monthlyPlanDailyColumnsTableRef = ref<HTMLDivElement>();
    const workplaceId = Number(root.$route.params.workplaceId);
    useBudgetGroupsProvider({ workplaceId });
    useSearchConditionsProvider({ workplaceId });
    useMonthlyPlanUrlParametersProvider();
    useDisplayConditionsProvider();
    useMonthlyPlanOverviewProvider();
    useMonthlyPlanProvider({ context });
    useActualDataFetchModalProvider();
    useInputValidationsProvider();
    useBudgetGroupPlanBoardMiscProvider();
    useMacroOperationProvider();
    const { budgetGroups } = useBudgetGroups();
    const { budgetGroup, selectedBudgetGroupId, dt } = useSearchConditions();
    const { state: displayState, changeURLQuery, changeShowMode, waitLoading, finishLoading } = useDisplayConditions();
    const { state: overviewState } = useMonthlyPlanOverview();
    const { state, initMonthlyPlan, refreshMonthlyPlan, updateDaySegmentData, focusMemo } = useMonthlyPlan();
    const {
      showActualDataFetchModal,
      hideActualDataFetchModal,
      showsActualDataFetchModal,
      fetchActualData,
      isLoading: isActualDataLoading,
    } = useActualDataFetchModal();
    const { getIsMinusAvailable, onValueInput } = useInputValidations();
    const { updateMiscPlanValue, updateMiscActualValue, updateTargetMonthlyValue, updateMemo } =
      useBudgetGroupPlanBoardMisc();
    const { macroOperationIndex, updateDayPlanData, updateDayActualData } = useMacroOperation();
    const { uploadMonthlyPlanCsv } = useUploadMonthlyPlanCsv({ budgetGroup });
    initMonthlyPlan({
      macroOperationIndex,
    });
    const memoRef = ref<HTMLTextAreaElement[] | null>(null);
    const textareaState = reactive<TextareaState>({
      height: TEXTAREA_DEFAULT_HEIGHT,
    });
    const csvState = reactive<CsvState>({
      csvFileName: '',
      csvData: [],
    });
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect.height === 0) {
        // stateの中身がリセットされるタイミングで高さが０で設定されてしまうため
        // stateのリセット時にtextareaの高さも初期値にする
        textareaState.height = TEXTAREA_DEFAULT_HEIGHT;
        return;
      }
      // 無限ループ対策のため直前の高さとの差分が閾値を超えた場合にのみ高さを変更
      // 値が大きいほど少しづつ高さを変更した時のカクつきが大きい
      const dh = Math.abs(textareaState.height - entries[0].contentRect.height);
      const threshold = 6;
      if (dh > threshold) {
        textareaState.height = Math.round(entries[0].contentRect.height);
      }
    });
    onUpdated(async () => {
      memoRef.value?.forEach((value) => {
        resizeObserver.observe(value);
      });
    });
    const onActualDataFetchModalVisibleStateChange = (isVisible: boolean) => {
      if (isVisible) {
        showActualDataFetchModal();
      } else {
        hideActualDataFetchModal();
      }
    };

    const onDisplayButtonClick = async () => {
      waitLoading();

      const _budgetGroup = budgetGroups.value.find((budgetGroup) => budgetGroup.id === selectedBudgetGroupId.value);
      if (!isExist(_budgetGroup)) {
        return;
      }
      budgetGroup.value = _budgetGroup;
      changeURLQuery();
      await refreshMonthlyPlan();

      finishLoading();
    };

    const onSegmentDisplayToggleClick = (task: TaskMaster) => {
      task.isSegmentOpened = !task.isSegmentOpened;
    };

    const onMonthlyPlanLabelsTableScroll = (v: MouseEvent & { target: HTMLDivElement }) => {
      if (!isExist(monthlyPlanDailyColumnsTableRef.value)) {
        return;
      }
      monthlyPlanDailyColumnsTableRef.value.scrollTop = v.target.scrollTop;
    };

    const onMonthlyPlanDailyColumnsTableScroll = (v: MouseEvent & { target: HTMLDivElement }) => {
      if (!isExist(monthlyPlanLabelsTableRef.value)) {
        return;
      }
      monthlyPlanLabelsTableRef.value.scrollTop = v.target.scrollTop;
    };

    const targetValueTabIndexCount = 2;

    const targetDatesLength = computed(() => state.targetDates.length);

    const formatHeadcount = (_value?: number, numberOfDecimalPlaces: number = 1) => {
      if (!isExist(_value) || _value.toString().length === 0) {
        return _value;
      }
      const denominator = Math.pow(10, numberOfDecimalPlaces);
      const tmpValue = (_value * denominator) / (displayState.headcountMode === HEADCOUNT_MODE.HEADCOUNT ? 8 : 1);
      const value = Math.round(tmpValue);
      // value が -0 だった場合に、addComma(-0) の結果 '-0' という文字列に変換されてしまうのを避けるため value が 0(-0) のときは 0 という三項演算子にしている
      const commaValue = addComma(value === 0 ? 0 : value / denominator);
      return commaValue.includes('.') || numberOfDecimalPlaces === 0 ? commaValue : `${commaValue}.0`;
    };

    const hasError = (memo: string | undefined): boolean => {
      return (memo?.length ?? 0) > MEMO_MAX_LENGTH;
    };

    function beforeDownload(lists: TaskMaster[]): void {
      setCsvFileName();
      setCsvData(lists);
    }

    function setCsvFileName() {
      csvState.csvFileName = `monthly_board_quantity_${format(new Date(), 'yyyyMMddHHmmss')}.csv`;
    }

    function setCsvData(lists: TaskMaster[]) {
      // CSV header
      const header: string[] = ['シフトグループ名', '数量区分名', '初期/直前'];
      const beginningOfMonth = new Date(dt.value.getFullYear(), dt.value.getMonth(), 1);
      const endOfMonth = new Date(dt.value.getFullYear(), dt.value.getMonth() + 1, 0);
      const rangeDate = Math.min(differenceInDays(endOfMonth, beginningOfMonth), 30) + 1;
      const dates = Array.from({ length: rangeDate }).map((x, i) => format(addDays(beginningOfMonth, i), 'yyyy/MM/dd'));
      const blankArray = Array.from({ length: rangeDate }).map((x) => '');
      const csvHeaders = header.concat(dates);

      csvState.csvData = [];
      csvState.csvData[0] = csvHeaders;
      lists.forEach((row) => {
        if (row.isSegmentUsed === true) {
          row.segments.forEach((segment) => {
            csvState.csvData.push(
              [row.name, segment.name, '初期', ...blankArray],
              [row.name, segment.name, '直前', ...blankArray],
            );
          });
        } else {
          csvState.csvData.push([row.name, '', '初期', ...blankArray], [row.name, '', '直前', ...blankArray]);
        }
      });
    }

    return {
      state,
      csvState,
      overviewState,
      displayState,
      budgetGroups,
      dt,
      selectedBudgetGroupId,
      showActualDataFetchModal,
      hideActualDataFetchModal,
      fetchActualData,
      formatDayOfWeek,
      formatYearMonth,
      formatValue,
      formatHeadcount,
      focusMemo,
      updateTargetMonthlyValue,
      updateDayPlanData,
      updateDayActualData,
      updateDaySegmentData,
      updateMemo,
      updateMiscPlanValue,
      updateMiscActualValue,
      onValueInput,
      onActualDataFetchModalVisibleStateChange,
      onMonthlyPlanLabelsTableScroll,
      onMonthlyPlanDailyColumnsTableScroll,
      onDisplayButtonClick,
      onSegmentDisplayToggleClick,
      isExist,
      hasError,
      getIsMinusAvailable,
      changeShowMode,
      isActualDataLoading,
      showsActualDataFetchModal,
      ShowModeOptions,
      HeadcountModeOptions,
      targetValueTabIndexCount,
      targetDatesLength,
      monthlyPlanLabelsTableRef,
      monthlyPlanDailyColumnsTableRef,
      textareaState,
      memoRef,
      uploadMonthlyPlanCsv,
      beforeDownload,
    };
  },
});
