import { BudgetGroup } from 'src/models/budgetGroup';
import { TimeInteger } from 'src/models/common';
import { PlanBoardIndexResult } from 'src/models/planBoard';
import { WorkplaceExtension } from 'src/models/workplaceExtension';

/**
 * 各工程の概要情報
 * 表で言うところの左側の情報
 */
export type PlanBoardHeader = {
  name: string;
  backgroundColor: string;
  amountWorkBeforehand: number | null; // 事前に稼働した数量: 表示に直接利用しないが「数量」の計算時に利用
  targetQuantity: number; // 目標数量: 表示上では「数量」
  excessOrDeficiency: number | null; // 目標数量と予測数量の差: 表示上では「過不足」
  startTime: string; // 開始時間
  endTime: string; // 終了時間
  productivity: number; // timetable_header に登録された生産性: 表示上では「生産性」
  plannedProductivity: number; // timetable_master に登録された planned_productivity: 表示上では「標準」
  totalHeadcount: number; // 合計人時: 表示上では「投入人時」
  requiredHeadcount: number | null; // 「必要人時」
  maxAllocations?: number | null; // 「配置限界」
  validation: {
    // 各値の検証結果: 「割り付け」時のバリデーション結果を保持し、問題がある場合は表示に反映する
    targetQuantity: boolean;
    startTime: boolean;
    endTime: boolean;
    productivity: boolean;
    maxAllocations: boolean;
  };
};

/**
 * 1 ブロックの人時情報
 */
export type PlanBoardHeadcountData = {
  headcount: number;
  backgroundColor: string;
  isStartTime: boolean; // 開始時間かどうか: 黄色いボーダーの表示に利用
  isEndTime: boolean; // 終了時間かどうか: 黄色いボーダーの表示に利用
};

/**
 * 1 時間単位の headcount 情報の配列が入った配列
 */
export type PlanBoardHeadcountHourBlocks = Array<PlanBoardHeadcountData[]>;

/**
 * 1 時間ごとの表示に必要な情報
 */
export type PlanBoardHourBlock = {
  totalHeadcount: number; // 合計人数: 表示に利用しないが他の情報の計算に利用
  quantity: number; // 経過数量: この時間までの合計数量
  completionRatio: number; // 完了率: 目標数量に対して何 % 達成しているかの表示に利用
  headcountList: PlanBoardHeadcountData[]; // 人時情報配列: 人時と背景色情報を持つ配列
};

/**
 * 工程情報
 * 表で言うところの 1 行分の情報を持つ
 */
export type PlanBoardTimetable = {
  id: number;
  masterId: number;
  header: PlanBoardHeader;
  boardHourBlocks: PlanBoardHourBlock[];
  isChanged: Boolean;
};

type DayHeadcount = {
  total: number;
  targetTimeRange: number;
};

export type PlanBoardState = {
  workplaceId: string;
  workplaceExtension: WorkplaceExtension;
  budgetGroup: BudgetGroup | null;
  budgetGroups: BudgetGroup[];
  baseDate: Date | null;
  timetables: PlanBoardTimetable[];
  surplusTimetable: PlanBoardTimetable | null;
  displayTimes: string[]; // 表示時間一覧 例) ['8:00', '9:00', ..., '20:00']
  displayStartHour: number; // 表示開始時間: 初期スクロール位置
  displayHourPeriod: number; // 表示したい時間数 例) 48 の場合は 0:00~47:00 の時間を表示する
  totalHeadcountHourBlocks: PlanBoardHeadcountHourBlocks; // 単位時間ごとの合計人時
  blockLengthPerHour: number; // 1 時間内の単位時間数 例) 単位時間が 15 分の場合 4
  apiResponse: PlanBoardIndexResult | null; // API のレスポンス保持用: 変更されたかどうかの判定に利用
  lastSaveTimestamp: string; // 最後に保存された時刻
  lastSavedBy: string; // 最後に保存したユーザー名
  isChanged: boolean; // 内容が変更されたかどうか: 「変更あり」ラベルの表示に利用
  targetStartTime: TimeInteger; // サマリ欄の計算で利用する開始時刻
  targetEndTime: TimeInteger; // サマリ欄の計算で利用する終了時刻
  isLoading: boolean;
};

export type PlanBoardDragState = {
  targetTimetableIndex: number; // ドラッグ先の Timetable の index
  draggedTimetableIndex: number; // ドラッグ元の Timetable の index
  draggedHeadcountIndex: number; // ドラッグしている人時情報の index
};

export type PlanBoardOverviewState = {
  headcountTotal: {
    shift: DayHeadcount;
    required: DayHeadcount;
    excessOrDeficiency: DayHeadcount;
  };
  shiftInputRegular: DayHeadcount;
  shiftInput: Array<{
    name: string;
    headcount: DayHeadcount;
  }>;
  shiftInputTotal: DayHeadcount;
  checkList: Array<{
    name: string;
    key: string;
    icon: string;
    color: string;
    total: number;
    targetTimeRange: number;
  }>;
  memo: string;
};

export type PlanBoardBreakTime = {
  startTime: TimeInteger;
  endTime: TimeInteger;
};

export type PlanBoardStaffForAllocation = {
  startTime: TimeInteger;
  endTime: TimeInteger;
  breakTimes: PlanBoardBreakTime[];
  skills: number[];
  timeBlocks: Array<{
    isAllocated: boolean;
  }>;
};

export const TIMELINE_COLUMN_WIDTH = 100; // 時間列の 1 列分の幅

export const MAX_STAFF_SKILL_COUNT = 5; // スタッフスキルの最大数

export const PLAN_BOARD_SURPLUS_MASTER_ID = -12; // 余剰の疑似 master_id
export const PLAN_BOARD_BREAK_TIME_MASTER_ID = -13; // 休憩の疑似 master_id
